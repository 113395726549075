
import { isAuthenticated } from "../auth"
import config from "../config";

const { token, store } = isAuthenticated();

// const API = process.env.API;
let API = config.API_URL;


// export const login = async (dispatch,user)=>{
//     dispatch(loginStart());
//     try{
//         const res = await publicRequest.post("/auth/login",user);
//         dispatch(loginSuccess(res.data));
//     }catch(err){
//         dispatch(loginFailure());
//     }
// }

export const login = (user) => {
    // console.log(name,email,password)
    return fetch(`${API}/signin`, {
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": "application/json"
        },
        body: JSON.stringify(user)
    })
        .then(res => {
            return res.json()
        })
        .catch(err => {
            console.log(err);
        })
}

export const getProducts = () => {
    // dispatch(getProductStart());
    // try{
    //     const res =  await userRequest.post(`/products/${store?._id}`);
    //     dispatch(getProductSuccess(res.data));
    // }catch(err){
    //     dispatch(getProductFailure());
    // }


    return fetch(`${API}/products/${store?._id}`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            "Content-Type": "application/json"
        },

    })
        .then(res => {
            return res.json()
        })
        .catch(err => {
            console.log(err);
        })

}

export const getProduct = (productId) => {
    return fetch(`${API}/product/find/${productId}`, {
        method: "GET"
    })
        .then(response => {
            return response.json()
        })
        .catch(err => {
            console.log(err);
        })
}

export const deleteProducts = async (id) => {
    return fetch(`${API}/product/delete/${store._id}/${id}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
    })
        .then(response => {
            return response.json()
        })
        .catch(err => {
            console.log(err);
        })
}

// export const addProduct = async (product,dispatch)=>{
//     dispatch(addProductStart());
//     try {
//         //update by axios
//         const res = await userRequest.post(`/product/create/61fe4d0b78b2abdc68cf4510`,product);
//         dispatch(addProductSuccess(res.data));
//     } catch (err) {
//         dispatch(updateProductFailure());
//     }
// }

// const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxZmU0ZDBiNzhiMmFiZGM2OGNmNDUxMCIsImlhdCI6MTY0NDA3OTkyMX0.sS1uX7kVBTKrZhxGqhm0aieC_bXc9drDgTGzCLLzZ90";


export const createProduct = (product) => {
    console.log(product);
    // return fetch(`${API}/product/create/${userId}`, {
    return fetch(`${API}/product/create/${store?._id}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(product)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const updateProduct = (productId, product) => {
    // return fetch(`${API}/product/${productId}/${userId}`,{
    return fetch(`${API}/product/update/${store?._id}/${productId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(product)
    })
        .then(response => {
            return response.json()
        })
        .catch(err => {
            console.log(err);
        })

}


export const getStore = () => {
    return fetch(`${API}/store/${store?._id}`, {
        method: "GET",
        headers: {
            Accept: 'application/json',
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
    })
        .then(res => {
            return res.json()
        })
        .catch(err => {
            console.log(err);
        })
}

export const createTransaction = (transaction) => {
    return fetch(`${API}/createTransaction/${store?._id}`, {
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(transaction)
    })
        .then(res => {
            return res.json()
        })
        .catch(err => {
            console.log(err);
        })
}

export const activateSubscription = (expiresAt) => {
    return fetch(`${API}/store/${store?._id}`, {
        method: "PUT",
        headers: {
            Accept: 'application/json',
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ isSubscribed: true, expiresAt: expiresAt })
    })
        .then(res => {
            return res.json()
        })
        .catch(err => {
            console.log(err);
        })
}

export const getTransactions = async () => {
    return fetch(`${API}/transaction/find/${store?._id}`, {
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json()
        })
        .catch(err => {
            console.log(err);
        })
}