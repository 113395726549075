import { Link, useParams } from "react-router-dom";
import "./product.css";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { productData } from "../../dummyData"
import { Publish } from "@material-ui/icons";
import { useState } from "react";
import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import app from "../../firebase";
import { getProduct, updateProduct } from "../../redux/apiCalls";
import { isAuthenticated } from "../../auth";
import {toast} from 'react-toastify'

export default function Product() {


  const params = useParams();
  const productId = params.productId
  const [product, setProduct] = useState({
    name: '',
    description: '',
    price: '',
    link: '',
    photo: []
  });

  const { store, token } = isAuthenticated()

  const storage = getStorage(app);
  const [photos, setPhotos] = useState([])
  const [files, setFiles] = useState([]);
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const {
    name,
    description,
    price,
    link,
    photo
  } = product;


  const init = () => {
    getProduct(productId)
      .then(data => {
        if (data.error) {
          setProduct({ ...product, error: data.error });
        }
        else {
          setProduct(data)
        }
      })

  };

  useEffect(() => {
    init()
  }, []);

  const handleChange = name => event => {
    const value = event.target.value;
    setProduct({ ...product, [name]: value});
  };

  console.log(product);

  const handleClick = event => {
    event.preventDefault();
    toast.info(
      "Product is being updated!"
  )
    updateProduct(productId,product).then(data => {
      if (data.error) {
        setError(data.error)
      } else{
          window.location.href="/products";
      }
    });
  };

  return (
    <div className="product">
      <div className="productTitleContainer">
        <h1 className="productTitle">Product</h1>
        <Link to="/newproduct">
          <button className="productAddButton">Create</button>
        </Link>
      </div>
      <div className="productTop">

        <div className="productTopRight">
          <div className="productInfoTop">
            <img src={product?.photo[0]} className="productInfoImg" />
            <span className="productName">{product?.name}</span>
          </div>
          <div className="productInfoBottom">
            <div className="productInfoItem">
              <span className="productInfoKey">ID: {product?._id}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="productBottom">
        <form className="productForm">
          <div className="productFormLeft">
            <label>Product Name</label>
            <input name="name" type="text" value={product.name} placeholder={product?.name} onChange={handleChange('name')} />
            <label>Description</label>
            <textarea name="description" rows="4" cols="50" value={product.description} type="text" placeholder={product?.description} onChange={handleChange('description')} />
            <label>Price</label>
            <input name="price" value={product.price} type="text" placeholder={product?.price} onChange={handleChange('price')} />
            <input name="link" value={product.link} type="text" placeholder={product?.link} onChange={handleChange('link')} />
            <button className="productButton" onClick={handleClick}>Update</button>
          </div>
        </form>
      </div>
    </div>
  );
}
