import { useState } from "react";
import "./newProduct.css";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import app from '../../firebase';
import { addProduct, createProduct } from "../../redux/apiCalls";
import Dropzone, { useDropzone } from 'react-dropzone';
import { useDispatch } from "react-redux";
import { Add } from "@material-ui/icons";
import { isAuthenticated } from "../../auth";
import {toast} from 'react-toastify'


export default function NewProduct() {
  const { store } = isAuthenticated();
  const [inputs, setInputs] = useState({});
  const [photos, setPhotos] = useState([])
  const [files, setFiles] = useState([]);
  const [category, setCat] = useState([null]);
  const [product, setProduct] = useState({});
  const dispatch = useDispatch();
  const storage = getStorage(app);

  const handleChange = (e) => {
    setInputs(prev => {
      return {
        ...prev, [e.target.name]: e.target.value
      }
    })
  }

  const handleCategories = (e) => {
    setCat(e.target.value.split(","));
  }




  // const handleClick = (e) => {
  //   e.preventDefault();
  //   const fileName = new Date().getTime() + file.name;
  //   const storage = getStorage(app);
  //   const storageRef = ref(storage, fileName);
  //   const uploadTask = uploadBytesResumable(storageRef, file);

  //   // Register three observers:
  //   // 1. 'state_changed' observer, called any time the state changes
  //   // 2. Error observer, called on failure
  //   // 3. Completion observer, called on successful completion
  //   uploadTask.on(
  //     "state_changed",
  //     (snapshot) => {
  //       // Observe state change events such as progress, pause, and resume
  //       // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
  //       const progress =
  //         (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //       console.log("Upload is " + progress + "% done");
  //       switch (snapshot.state) {
  //         case "paused":
  //           console.log("Upload is paused");
  //           break;
  //         case "running":
  //           console.log("Upload is running");
  //           break;
  //         default:
  //       }
  //     },
  //     (error) => {
  //       // Handle unsuccessful uploads
  //     },
  //     () => {
  //       // Handle successful uploads on complete
  //       // For instance, get the download URL: https://firebasestorage.googleapis.com/...
  //       getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
  //         const product = { ...inputs, img: downloadURL, categories: cat };
  //         addProduct(product, dispatch);
  //       });
  //     }
  //   );
  // };
  // console.log(file);

  const uploadAllPhotos = async (files) => {
    for (var file of files) {
      await uploadImageAsPromise(file)
        .then((res) => {
          photos.push(res);
        })
        .catch((err) => {
          console.log(err);
        })
    }
  }

  const uploadImageAsPromise = async (file) => {
    return new Promise((resolve, reject) => {

      const fileName = new Date().getTime() + file.name;
      const storageRef = ref(storage, fileName);
      const uploadTask = uploadBytesResumable(storageRef, file)

      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
            default:
          }
        },
        (err) => {
          console.log(err);
          reject(err)
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((downloadURL) => {
              resolve(downloadURL);
            });
        }
      );
    })
  }
  
  const handleClick = async (e) => {
    e.preventDefault();
    toast.info(
      "Product is being created!"
  )
    await uploadAllPhotos(files).then(() => {      
      const uploadPayload = { ...inputs, photo: photos, category: category, store: store._id };
      // console.log(uploadPayload);
      
      createProduct(uploadPayload).then(()=>{
        window.location.href="/products";
        
      });
    })
    
    // addProduct(uploadPayload, dispatch);
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      let pho = []
      acceptedFiles.map(file => {
        pho.push(Object.assign(file, { preview: URL.createObjectURL(file)}))
      })
      setFiles(files.concat(pho))
      // handleImageDrop(acceptedFiles);
    }
  });

  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    padding: 4,
    boxSizing: 'border-box'
  };

  const closeBtn = {
    position: "absolute",
    top: "2px",
    right: "2px",
    zIndex: "100"
  }

  const thumbs = files.map(file => (
    
    <div style={thumb} key={file.name}>
      <span style={closeBtn}>X</span>
      <img
        src={file.preview}
        style={{ minWidth: "300px", width: "300px" }}
        alt=""
      />
    </div>
  ));

  return (
    <div className="newProduct">
      <h1 className="addProductTitle">New Product</h1>
      <form className="addProductForm">
        <div className="addProductItem">
          <h4>Post Photo</h4>
          <div className="form-group mt-3 d-flex-wrap">
            <div {...getRootProps({ className: 'dropzone' })} className="d-flex border border-dark align-items-center justify-content-center mb-4 col col-lg-12 col-md-12" style={{ border: "1px dotted gray", height: "240px" }}>
              <input {...getInputProps()} />
              <Add style={{ fontSize: '3rem' }} />
            </div>
            {thumbs}
          </div>

        </div>
        <div className="addProductItem">
          <label>Title</label>
          <input name="name" type="text" placeholder="Apple Airpods" onChange={handleChange} />
        </div>
        <div className="addProductItem">
          <label>Description</label>
          <textarea name="description" rows="10" cols="50" type="text" placeholder="description" onChange={handleChange} />
        </div>
        <div className="addProductItem">
          <label>Price</label>
          <input name="price" type="text" placeholder="400" onChange={handleChange} />
        </div>
        <div className="addProductItem">
          <label>Categories</label>
          <input type="text" placeholder="photo frame,cake" onChange={handleCategories} />
        </div>
        <div className="addProductItem">
          <label>Product Link</label>
          <input name="link" type="text" placeholder="e.g. insta link" onChange={handleChange} />
        </div>
        {/* <div className="addProductItem">
          <label>Stock</label>
          <select>
            <option value='true'>Yes</option>
            <option value='false'>No</option>
          </select>
        </div> */}
        <button onClick={handleClick} className="addProductButton">Create</button>
      </form>
    </div>
  );
}
