import { HistoryRounded } from "@material-ui/icons";
import React from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";

import { signout } from "../../auth";
import "./topbar.css";

export default function Topbar() {

  
  

  return (
    <div className="topbar">
      <div className="topbarWrapper">
        <div className="topLeft">
        <Link to="/" style={{textDecoration:"none"}}>
          <span className="logo">ProductJi Admin</span>
          </Link>
        </div>
        <div className="topRight">
          <span className="nav-link" style={{ cursor: "pointer" }}
            onClick={() =>
              signout().then(() => {
              window.location.reload();
                console.log("signed out");
              }
              )

            }>Sign Out</span>
        </div>
      </div>
    </div>
  );
}
