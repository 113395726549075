// import axios from "axios";

// // const BASE_URL = "http://localhost:5000/api/";
// // console.log(localStorage.getItem("persist:root"))
// // const TOKEN = JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user).currentUser.accessToken;

// // console.log(localStorage.getItem("persist:root").user);
// let TOKEN="";

// if(localStorage?.getItem("persist:root")===undefined){
//     TOKEN="";
// }else{
//     if(JSON.parse(localStorage?.getItem("persist:root"))?.user){
//         if(JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user).currentUser != null){
//             TOKEN=JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user).currentUser.accessToken;
//         }
//         else {
//           TOKEN = "";  
//         }
//     }
//     else{
//         TOKEN = ""; 
//     }
// }


// // const TOKEN = JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user).currentUser.accessToken;


// export const publicRequest = axios.create({
//     baseURL:BASE_URL
// })

// export const userRequest = axios.create({
//     baseURL:BASE_URL,
//     headers:{token:`Bearer ${TOKEN}`}
// })

import axios from "axios";
import config from "./config";
import { isAuthenticated } from "./auth";

const BASE_URL = config.API_URL

let token="";

if(isAuthenticated()){
   token = JSON.parse(localStorage.getItem("jwt")).token;
}
  

// if(TOKEN != "") TOKEN=JSON.parse(JSON.parse(localStorage?.getItem("persist:root"))?.user)?.currentUser.accessToken;

// TOKEN="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxZmU0ZDBiNzhiMmFiZGM2OGNmNDUxMCIsImlhdCI6MTY0NDA3OTkyMX0.sS1uX7kVBTKrZhxGqhm0aieC_bXc9drDgTGzCLLzZ90";



export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers:  {Authorization:`Bearer ${token}`} ,
});