import "./widgetSm.css";
import { Visibility } from "@material-ui/icons";
import { useState } from "react";
import { useEffect } from "react";
import { userRequest } from "../../requestMethods";
import { activateSubscription, createTransaction, getStore } from "../../redux/apiCalls";
import { isAuthenticated } from "../../auth";
import moment from "moment";
import { Redirect } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import config from "../../config";

let API = config.API_URL;

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}

export default function WidgetSm() {

  const [store, setStore] = useState({});

  useEffect(() => {
    if (isAuthenticated()) {
      const storeId = JSON.parse(localStorage.getItem('jwt')).store?._id;
      getStore()
        ?.then(data => {
          if (data?.error) {
            console.log(data.error);
          }
          else {
            console.log(data);
            setStore(data)
          }
        })
    }
  }, [])

  async function displayRazorpay() {
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?')
      return
    }

    const data = await fetch(`${API}/api/razorpay/monthly`, { method: 'POST' }).then((t) =>
      t.json()
    )

    const options = {
      key: process.env.REACT_RAZORPAY_KEY_ID,
      currency: data.currency,
      amount: data.amount.toString(),
      order_id: data.id,
      name: 'Standard Selling Plan',
      description: 'We want you to be a part of Hashbuy family',
      image: "https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Round&hairColor=BrownDark&facialHairType=Blank&clotheType=Hoodie&clotheColor=Gray02&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light",
      handler: function (response) {

        const transaction = {
          store: isAuthenticated().store._id,
          amount: data.amount,
          paymentId: response.razorpay_payment_id,
          orderId: response.razorpay_order_id,
          signature: response.razorpay_signature,
          phoneNo: '6666456577',
          email: store.email,
          expiresAt: moment(Date.now()).add(30, 'days').toISOString()
        }
        // console.log(transaction);

        createTransaction(transaction)
          .then(data => {
            if (data.error) {
              console.log(data.error);
            }
            else {
              console.log(data)
            }
          })

        activateSubscription(moment(Date.now()).add(30, 'days').toISOString())
          .then(data => {
            if (data.error) {
              console.log(data.error);
            }
            else {
              console.log(data)
            }
          })
        window.location.reload();
      },
    }
    const paymentObject = new window.Razorpay(options)
    paymentObject.on('payment.failed', function (response) {
      console.log(response.errorss);
    });
    paymentObject.open()
  }

  // if(store === undefined){
  //   return <CommonLoading/>
  // }

  const handleFreeTrial = () => {

    confirmAlert({
      title: 'You are going to start your 15 day free trial. Use it wisely.',
      message: 'Are you sure to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            activateSubscription(moment(Date.now()).add(15, 'days').toISOString())
              .then(data => {
                if (data.error) {
                  console.log(data.error);
                }
                else {
                  console.log(data)
                }
              })
            window.location.reload();
          }
          //  onClick: () => alert('Click Yes')
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    })
  }

  return store?.isSubscribed ? <div></div> : (
    <div>
      <h3>Activate Subscription</h3>
      <div className="widgetSm">
        <span className="widgetSmTitle">Free Trial</span>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui officiis autem consectetur id, quaerat aperiam quisquam rerum, aliquam laborum saepe ex vitae molestiae non perferendis explicabo distinctio! Neque, sit sequi?</p>
        <button
          onClick={handleFreeTrial}
          target="_blank"
          rel="noopener noreferrer" className="btn btn-primary App-link" style={{ marginTop: 20 }}>Buy Now</button>
      </div>

      <div className="widgetSm">
        <span className="widgetSmTitle">Monthly Plan</span>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa excepturi esse recusandae corrupti eligendi inventore iste temporibus quam, id maxime enim est rem eum deleniti voluptatibus numquam et incidunt harum, neque animi ab aut maiores cupiditate. Eaque corrupti nesciunt necessitatibus culpa ex omnis accusantium sint, aliquam dolor? Nostrum, perspiciatis quaerat! </p>
        <button
          onClick={displayRazorpay}
          target="_blank"
          rel="noopener noreferrer" className="btn btn-primary App-link" style={{ marginTop: 20 }}>Buy Now</button>
      </div>
    </div>
  );
}
