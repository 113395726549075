import "./featuredInfo.css";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import { useState } from "react";
import { useEffect } from "react";
import {userRequest} from '../../requestMethods';
import { isAuthenticated } from "../../auth";
import { getStore } from "../../redux/apiCalls";
import moment from 'moment';

export default function FeaturedInfo() {
  
  const [store, setStore] = useState({});

  useEffect(() => {
      if (isAuthenticated().store) {
          const storeId = JSON.parse(localStorage.getItem('jwt')).store._id;
          getStore()
              .then(data => {
                  if (data?.error) {
                      console.log(data.error);
                  }
                  else {
                      console.log(data);
                      setStore(data)
                  }
              })
      }
  }, [])

    const now = moment(Date.now())
    const expiresAt = moment(store?.expiresAt)
    const daysLeft = expiresAt?.diff(now, 'days');

  return store == {} ?<h1>Loading...</h1> : (
    <div className="featured">
      <div className="featuredItem">
        <span className="featuredTitle">Days Left</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{store?.isSubscribed 
          ?daysLeft
          :<span className="featuredMoney">----</span>
           }</span>
        </div>
      </div>
      <div className="featuredItem">
        <span className="featuredTitle">Current Plan</span>
        <div className="featuredMoneyContainer">
        {store?.isSubscribed 
        ?<span className="featuredMoney">30 Days Plan</span>
        :<span className="featuredMoney">Expired</span>
            }
          
        </div>
      </div>
    </div>
  );
}
