import "./userList.css";
import "../../components/widgetLg/widgetLg.css"
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline } from "@material-ui/icons";
import { userRows } from "../../dummyData";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { userRequest } from "../../requestMethods";
import moment from "moment";
import { format } from "timeago.js";
import { getTransactions } from "../../redux/apiCalls";

export default function UserList() {
  const [data, setData] = useState(userRows);

  const handleDelete = (id) => {
    setData(data.filter((item) => item.id !== id));
  };

  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    try {
      getTransactions().then(data => {
        setTransactions(data)
      })
    } catch (error) {
      console.log(error);
    };
  }, [])
  
  const columns = [
    { field: "id", headerName: "Payment Id", width: 200 },
    {
      field: "user",
      headerName: "Order Id",
      width: 200,
      renderCell: (transactions) => {
        return (
          <div className="userListUser">
            {/* <img className="userListImg" src={params.row.avatar} alt="" /> */}
            {transactions.map((transaction) =>
            transaction.orderId)}
          </div>
        );
      },
    },
    { field: "email", headerName: "Email", width: 200 },
    {
      field: "status",
      headerName: "Status",
      width: 120,
    },
    {
      field: "transaction",
      headerName: "Transaction Amount",
      width: 160,
    },
  ];

  return (
    // <div className="userList">
    //   <DataGrid
    //     rows={data}
    //     disableSelectionOnClick
    //     columns={columns}
    //     pageSize={8}
        
    //   />
    // </div>
    <div className="widgetLg">
      <h3 className="widgetLgTitle">Last transactions</h3>
      <table className="widgetLgTable">
        <tr className="widgetLgTr">
          <th className="widgetLgTh">Payment Id</th>
          <th className="widgetLgTh">Created</th>
          <th className="widgetLgTh">Amount</th>
          <th className="widgetLgTh">Order Id</th>
          <th className="widgetLgTh">Valid Until</th>
        </tr>
        {
          transactions.map((transaction)=>{
           return (<tr className="widgetLgTr" key={transaction._id}>
            <td className="widgetLgUser">
              <span className="widgetLgName">{transaction.paymentId}</span>
            </td>
            <td className="widgetLgDate">{moment(transaction.createdAt).format('DD/MM/YYYY')}</td>
            <td className="widgetLgAmount">₹{transaction.amount / 100}</td>
            <td className="widgetLgStatus">{transaction.orderId}</td>
            <td className="widgetLgStatus">{moment(transaction.expiresAt).format('DD/MM/YYYY')}</td>
          </tr>)
          })

        }
      </table>
    </div>
  );
}
