import {createSlice} from '@reduxjs/toolkit';

export const productSlice = createSlice({
    name:"product",
    initialState:{
        products:[],
        isFetching:false,
        error:false,
    },
    reducers:{
        //get producsts
        getProductStart:(state)=>{
            state.isFetching=true
            state.error=false
        },
        getProductSuccess:(state,action)=>{
            state.products=action.payload
            state.isFetching=false
        },
        getProductFailure:(state)=>{
            state.isFetching=false
            state.error=true
        },
        //delete products
        deleteProductStart:(state)=>{
            state.isFetching=true
            state.error=false
        },
        deleteProductSuccess:(state,action)=>{
            state.isFetching=false
            state.products.splice(
                //splice(index,1for deleting the given index)
               //findIndex will check the index and mathces with the payload 
                state.products.findIndex((item)=>item._id === action.payload),1
            );

        },
        deleteProductFailure:(state)=>{
            state.isFetching=false
            state.error=true
        },
        //update products
        updateProductStart:(state)=>{
            state.isFetching=true
            state.error=false
        },
        updateProductSuccess:(state,action)=>{
            state.isFetching=false
            //find index of the selected product and update
            state.products[
                state.products.findIndex((item)=>item._id === action.payload.id)
            ]=action.payload.product;
        },
        updateProductFailure:(state)=>{
            state.isFetching=false
            state.error=true
        },
        //add products
        addProductStart:(state)=>{
            state.isFetching=true
            state.error=false
        },
        addProductSuccess:(state,action)=>{
            state.isFetching=false
            state.products.push(action.payload);
        },
        addProductFailure:(state)=>{
            state.isFetching=false
            state.error=true
        },
    },
});

export const {getProductStart,getProductSuccess,getProductFailure,deleteProductFailure,deleteProductStart,deleteProductSuccess,updateProductStart,updateProductSuccess,updateProductFailure,addProductStart,addProductSuccess,addProductFailure} = productSlice.actions;

export default productSlice.reducer;