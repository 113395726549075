import React from 'react'
import { Redirect, useLocation } from 'react-router-dom';
import { isAuthenticated, isSubscribed } from '.';

const PrivateRoute = ({children}) => {
    let location = useLocation();

    if (!isAuthenticated()) {
        return <Redirect to="/signin" state={{ from: location }} replace />;
    }
    return children;
}

export default PrivateRoute
