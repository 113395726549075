import React, { useState } from 'react'
import { Redirect } from 'react-router-dom';

import { authenticate, isAuthenticated, signin } from "../../auth";
const Signin = () => {

    const [values, setValues] = useState({
        name: "",
        email: "",
        password: "",
        error: "",
        loading: false,
        redirectToReferrer: false
    });

    const { email, password, error, loading, redirectToReferrer } = values;

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setValues({ ...values, error: false, loading: true })
        signin({ email, password }).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, loading: false })
            }
            else {
                authenticate(data, () => {
                    window.location.reload();
                    setValues({
                        ...values,
                        redirectToReferrer: true
                    })
                })
            }
        })
    }

    const showError = () => {
        return <div className="alert alert-danger" style={{ display: error ? '' : "None " }}>
            {error}
        </div>
    }

    const showLoading = () => {
        return loading && (<div className="alert alert-info"><h2>Loading...</h2></div>)
    }

    const redirectUser = () => {
        if (redirectToReferrer) {
            if (isAuthenticated().store._id !== undefined) {
                return <Redirect to="/" />
            }
        }
    }


    const signInForm = () => {
        return <form action="" onSubmit={handleSubmit}>
            <div className="form-group">
                <label className='text-muted'>Email</label>
                <input onChange={handleChange('email')} type="email" className="form-control" value={email} />
            </div>

            <div className="form-group">
                <label className='text-muted'>Password</label>
                <input onChange={handleChange('password')} type="password" className="form-control" value={password} />
            </div>

            <button onClick={handleSubmit} className="btn bt-primary" type="submit">Submit</button>
        </form>
    }

    return (
        <div>
            <div className="container">
                {showLoading()}
                {showError()}
                {signInForm()}
                {redirectUser()}
            </div>
        </div>
    )
}

export default Signin
