import "./productList.css";
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline } from "@material-ui/icons";
import { Link, Redirect } from "react-router-dom";
import { useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import { deleteProducts, getProducts } from "../../redux/apiCalls";
import { useEffect } from "react";
import { isAuthenticated } from "../../auth";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'

export default function ProductList() {
  
  const [pro,setPro] = useState([]);

   let submit = (id) => {
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: ()=>handleDelete(id)
          //  onClick: () => alert('Click Yes')
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    })
  };

  useEffect(()=>{
    getProducts()
    .then(data => {
      if(data.error){
        console.log(data.error);
      }
      else{
        setPro(data)
      }
    })
  },[]);
 

  const handleDelete = (id) => {
    deleteProducts(id).then(data => {
      window.location.reload();
        // alert("Yay, the product was deleted, please refresh the page");
    })
  };

  const columns = [
    { field: "name", headerName: "Name", width: 200,
    renderCell: (params) => {
      return (
         
         <div>
           {params.row.name}
         </div>   
      );
    },
  },
    { field: "_id", headerName: "ID", width: 220 },
    {
      field: "product",
      headerName: "Product",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            <img className="productListImg" src={params.row.photo[0]} alt="" />
            {params.row.title}
          </div>
        );
      },
    },
    
    {
      field: "price",
      headerName: "Price",
      width: 160,
      
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/product/" + params.row._id}>
              <button className="productListEdit">Edit</button>
            </Link>
            <DeleteOutline
              className="productListDelete"
              onClick={()=>submit(params.row._id)}
              // onClick={() => handleDelete(params.row._id)}
              // onClick={this.submit(params.row._id)}
            />
          </>
        );
      },
    },
  ];

  return (
    <div className="productList">
      <DataGrid
        rows={pro}
        disableSelectionOnClick
        columns={columns}
        getRowId={(row)=>row._id}
        pageSize={8}
        checkboxSelection
      />
    </div>
  );
}
