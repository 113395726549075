import { useEffect, useState } from "react";
import { userRequest } from "../../requestMethods";
import { format } from 'timeago.js';
import "./widgetLg.css";
import moment from "moment";
import { getTransactions } from "../../redux/apiCalls";

export default function WidgetLg() {

  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    try {
      getTransactions().then(data => {
        setTransactions(data)
      })
    } catch (error) {
      console.log(error);
    };
  }, [])

  const Button = ({ type }) => {
    return <button className={"widgetLgButton " + type}>{type}</button>;
  };
  
    const transaction = transactions[transactions.length-1]
    return (
      <div  className="widgetLg">
        <h3 className="widgetLgTitle">Last transactions</h3>
        <table className="widgetLgTable">
          <tr className="widgetLgTr">
            <th className="widgetLgTh">Payment Id</th>
            <th className="widgetLgTh">Created</th>
            <th className="widgetLgTh">Amount</th>
            <th className="widgetLgTh">Order Id</th>
            <th className="widgetLgTh">Valid Until</th>
          </tr>
          {
            transaction
            && (<tr className="widgetLgTr" key={transaction._id}>
              <td className="widgetLgUser">
                <span className="widgetLgName">{transaction.paymentId}</span>
              </td>
              <td className="widgetLgDate">{format(transaction.createdAt)}</td>
              <td className="widgetLgAmount">₹{transaction.amount / 100}</td>
              <td className="widgetLgStatus">{transaction.orderId}</td>
              <td className="widgetLgStatus">{moment(transaction.expiresAt).format('DD/MM/YYYY')}</td>
            </tr>)
          }
        </table>
      </div>
    );
  }
