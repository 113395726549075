import Sidebar from "./components/sidebar/Sidebar";
import Topbar from "./components/topbar/Topbar";
import "./App.css";
import Home from "./pages/home/Home";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import UserList from "./pages/userList/UserList";
import User from "./pages/user/User";
import NewUser from "./pages/newUser/NewUser";
import ProductList from "./pages/productList/ProductList";
import Product from "./pages/product/Product";
import NewProduct from "./pages/newProduct/NewProduct";
import Login from "./pages/login/Login";
import { useSelector } from "react-redux";
import Signin from "./pages/login/Signin";
import { isAuthenticated } from "./auth";
import PrivateRoute from "./auth/PrivateRoute"
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function App() {
  const admin = isAuthenticated();
  return (
    <>
      <Router>
        <Switch>
          <Route path="/signin">
            {!admin ? <Signin /> : <Redirect to="/"></Redirect>}
          </Route>
          <>
            <Topbar />
            <div className="containner">
              <Sidebar />
              <Route exact path="/">
                <PrivateRoute ><Home /></PrivateRoute>
              </Route>
              <Route path="/users">
                <PrivateRoute ><UserList /></PrivateRoute>
              </Route>
              <Route path="/user/:userId">
                <PrivateRoute ><User /> </PrivateRoute>
              </Route>
              <Route path="/newUser">
                <PrivateRoute > <NewUser /> </PrivateRoute>
              </Route>
              <Route path="/products">
                <PrivateRoute > <ProductList /> </PrivateRoute>
              </Route>
              <Route path="/product/:productId">
                <PrivateRoute ><Product /> </PrivateRoute>
              </Route>
              <Route path="/newproduct">
                <PrivateRoute > <NewProduct /> </PrivateRoute>
              </Route>
              <ToastContainer autoClose={20000}/>
            </div>
          </>
        </Switch>
      </Router>
    </>
  );
}

// function App() {
//   let admin = false;
//   if (localStorage.getItem("persist:root") === undefined) {
//     admin = false;
//   } else {
//     if (JSON.parse(localStorage.getItem("persist:root"))?.user) {
//       if (JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user).currentUser != null) {
//         admin = JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user).currentUser.isAdmin;
//       }
//       else {
//         admin = false;
//       }
//     }
//     else {
//       admin = false;
//     }
//   }

//   console.log(admin)
//   return (
//     <Router>
//       <Switch>
//         <Route path="/login">
//           <Login />
//         </Route>
//         {admin && (<>
//           <Topbar />
//           <div className="container">
//             <Sidebar />

//             <Route exact path="/">
//               <Home />
//             </Route>
//             <Route path="/users">
//               <UserList />
//             </Route>
//             <Route path="/user/:userId">
//               <User />
//             </Route>
//             <Route path="/newUser">
//               <NewUser />
//             </Route>
//             <Route path="/products">
//               <ProductList />
//             </Route>
//             <Route path="/product/:productId">
//               <Product />
//             </Route>
//             <Route path="/newproduct">
//               <NewProduct />
//             </Route>
//           </div>
//         </>
//         )
//         }
//       </Switch>
//     </Router>
//   );
// }

export default App;
