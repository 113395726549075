import config from "../config";

let API = config.API_URL;

export const signup = (user) => {
    // console.log(name,email,password)
    return fetch(`${API}/signup`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": "application/json"
        },
        body: JSON.stringify(user)
    })
    .then(res => {
        return res.json()
    })
    .catch(err => {
        console.log(err);
    })
}

export const signin = (user) => {
    // console.log(name,email,password)
    return fetch(`${API}/signin`,{
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": "application/json"
        },
        body: JSON.stringify(user)
    })
    .then(res => {
        return res.json()
    })
    .catch(err => {
        console.log(err);
    })
}

export const authenticate = async (data,next) => {
    if(typeof window !== 'undefined'){
        localStorage.setItem('jwt',JSON.stringify(data))
        next()
    }
}

export const signout = () => {
    if(typeof window !== 'undefined'){
        localStorage.removeItem('jwt');
        return fetch(`${API}/signout`,{
            method: "GET",
            redirect: 'follow',
            Accept: "*/*"
        })
        .then(res => {
            return res.json();
        })
        .catch(err => {
            console.log(err);
        })
    }
}

export const isAuthenticated = () => {
    if(typeof window == 'undefined'){
        return false;
    }

    if(localStorage.getItem('jwt')){
        return JSON.parse(localStorage.getItem('jwt'))
    }
    else{
        return false;
    }
}


export const isAdmin = () => {
    if(isAuthenticated()){
        if(isAuthenticated().store.isSubscribed === true){
            return true
        }
        else{
            return false
        }
    }
    else{
        return false
    }
}
